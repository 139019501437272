import { useState } from 'react';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import { useMaskedInputProps } from 'react-native-mask-input';

import { FormControlTextInput } from '../form-control-text-input';
import { IconMobile } from '../icon';

import { isEmailValid } from './masks';

import { MaskInputProps } from '.';

export function EmailInput(props: MaskInputProps) {
  const [maskedEmail, setMaskedEmail] = useState('');

  const { onChangeText, value: maskedValue } = useMaskedInputProps({
    value: maskedEmail,
    onChangeText: (masked, _unmasked) => {
      setMaskedEmail(masked);
      props.onChange(_unmasked);
    },
  });

  const handleOnBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    isEmailValid(e.nativeEvent.text);
  };

  return (
    <>
      <FormControlTextInput
        label={'Enter your Email Address'}
        placeholder={'user@domain.com'}
        keyboardType="email-address"
        leftIcon={<IconMobile size={24} />}
        onChangeText={onChangeText}
        value={maskedValue}
        onBlur={handleOnBlur}
      />
    </>
  );
}
