{
  "data": {
    "url": "https://wmbuh5pfsvc3djbqvkkvddrk2u.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_region": "us-east-1",
    "api_key": "da2-m5pvldq3tbgbfhkzqtnfuysz5e",
    "default_authorization_type": "API_KEY",
    "authorization_types": [
      "AWS_LAMBDA",
      "AWS_IAM"
    ],
    "model_introspection": {
      "version": 1,
      "models": {
        "Restaurant": {
          "name": "Restaurant",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Restaurants",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        },
        "MenuItem": {
          "name": "MenuItem",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "customizations": {
              "name": "customizations",
              "isArray": false,
              "type": {
                "nonModel": "MenuItemCustomizations"
              },
              "isRequired": true,
              "attributes": []
            },
            "slug": {
              "name": "slug",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "badge": {
              "name": "badge",
              "isArray": false,
              "type": {
                "enum": "MenuItemBadge"
              },
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "MenuItems",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id",
                  "restaurantId",
                  "serviceMode"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": [
              "restaurantId",
              "serviceMode"
            ]
          }
        },
        "Menu": {
          "name": "Menu",
          "fields": {
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "metadata": {
              "name": "metadata",
              "isArray": false,
              "type": {
                "nonModel": "MenuMetadata"
              },
              "isRequired": false,
              "attributes": []
            },
            "sections": {
              "name": "sections",
              "isArray": true,
              "type": {
                "nonModel": "MenuSection"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Menus",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "restaurantId",
                  "serviceMode",
                  "region"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": true,
            "primaryKeyFieldName": "restaurantId",
            "sortKeyFieldNames": [
              "serviceMode",
              "region"
            ]
          }
        },
        "Order": {
          "name": "Order",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "ID",
              "isRequired": true,
              "attributes": []
            },
            "userId": {
              "name": "userId",
              "isArray": false,
              "type": "ID",
              "isRequired": false,
              "attributes": []
            },
            "createdAt": {
              "name": "createdAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            },
            "updatedAt": {
              "name": "updatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": false,
              "attributes": [],
              "isReadOnly": true
            }
          },
          "syncable": true,
          "pluralName": "Orders",
          "attributes": [
            {
              "type": "model",
              "properties": {}
            },
            {
              "type": "key",
              "properties": {
                "fields": [
                  "id"
                ]
              }
            },
            {
              "type": "auth",
              "properties": {
                "rules": [
                  {
                    "allow": "public",
                    "provider": "apiKey",
                    "operations": [
                      "get",
                      "list"
                    ]
                  }
                ]
              }
            }
          ],
          "primaryKeyInfo": {
            "isCustomPrimaryKey": false,
            "primaryKeyFieldName": "id",
            "sortKeyFieldNames": []
          }
        }
      },
      "enums": {
        "ServiceMode": {
          "name": "ServiceMode",
          "values": [
            "pickup",
            "delivery"
          ]
        },
        "MenuItemBadge": {
          "name": "MenuItemBadge",
          "values": [
            "none",
            "new",
            "limited"
          ]
        },
        "CustomizeOptionValuesVariantOverridesStatus": {
          "name": "CustomizeOptionValuesVariantOverridesStatus",
          "values": [
            "available",
            "unavailable",
            "unknown"
          ]
        },
        "CustomizeOptionValuesStatus": {
          "name": "CustomizeOptionValuesStatus",
          "values": [
            "available",
            "unavailable",
            "unknown"
          ]
        },
        "CustomizationOptionsType": {
          "name": "CustomizationOptionsType",
          "values": [
            "select",
            "checkbox",
            "stepper"
          ]
        },
        "MenuDisplayGroupsItemsType": {
          "name": "MenuDisplayGroupsItemsType",
          "values": [
            "flat",
            "sectioned"
          ]
        }
      },
      "nonModels": {
        "Image": {
          "name": "Image",
          "fields": {
            "altText": {
              "name": "altText",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "asset": {
              "name": "asset",
              "isArray": false,
              "type": {
                "nonModel": "ImageAsset"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CustomizeOptionValuesVariantOverrides": {
          "name": "CustomizeOptionValuesVariantOverrides",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "price": {
              "name": "price",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "upchargeCents": {
              "name": "upchargeCents",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "caloriesOffset": {
              "name": "caloriesOffset",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "CustomizeOptionValuesVariantOverridesStatus"
              },
              "isRequired": false,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "CustomizeOptionValuesVariants": {
          "name": "CustomizeOptionValuesVariants",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "CustomizeOptionValues": {
          "name": "CustomizeOptionValues",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "status": {
              "name": "status",
              "isArray": false,
              "type": {
                "enum": "CustomizeOptionValuesStatus"
              },
              "isRequired": false,
              "attributes": []
            },
            "upChargeCents": {
              "name": "upChargeCents",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "caloriesOffset": {
              "name": "caloriesOffset",
              "isArray": false,
              "type": "Int",
              "isRequired": true,
              "attributes": []
            },
            "variants": {
              "name": "variants",
              "isArray": true,
              "type": {
                "nonModel": "CustomizeOptionValuesVariants"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "CustomizationOptions": {
          "name": "CustomizationOptions",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "CustomizationOptionsType"
              },
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": true,
              "attributes": []
            },
            "defaultOption": {
              "name": "defaultOption",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "optionOrder": {
              "name": "optionOrder",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": {
                "nonModel": "CustomizeOptionValues"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "MenuDisplayGroupsItemSections": {
          "name": "MenuDisplayGroupsItemSections",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "options": {
              "name": "options",
              "isArray": true,
              "type": "String",
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "MenuDisplayGroupsItems": {
          "name": "MenuDisplayGroupsItems",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "type": {
              "name": "type",
              "isArray": false,
              "type": {
                "enum": "MenuDisplayGroupsItemsType"
              },
              "isRequired": false,
              "attributes": []
            },
            "sectionsItem": {
              "name": "sectionsItem",
              "isArray": true,
              "type": {
                "nonModel": "MenuDisplayGroupsItemSections"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "MenuItemCustomizations": {
          "name": "MenuItemCustomizations",
          "fields": {
            "topLevelOptions": {
              "name": "topLevelOptions",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "displayGroups": {
              "name": "displayGroups",
              "isArray": false,
              "type": {
                "nonModel": "MenuItemCustomizationsDisplayGroups"
              },
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuSectionItem": {
          "name": "MenuSectionItem",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "slug": {
              "name": "slug",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "descriptionShort": {
              "name": "descriptionShort",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": true,
              "attributes": []
            },
            "badge": {
              "name": "badge",
              "isArray": false,
              "type": {
                "enum": "MenuItemBadge"
              },
              "isRequired": false,
              "attributes": []
            },
            "_legacySlug": {
              "name": "_legacySlug",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "_legacyUrl": {
              "name": "_legacyUrl",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "MenuSection": {
          "name": "MenuSection",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "slug": {
              "name": "slug",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "url": {
              "name": "url",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "descriptionShort": {
              "name": "descriptionShort",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "items": {
              "name": "items",
              "isArray": true,
              "type": {
                "nonModel": "MenuSectionItem"
              },
              "isRequired": true,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        },
        "MenuSectionHeader": {
          "name": "MenuSectionHeader",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "slug": {
              "name": "slug",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "to": {
              "name": "to",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "displayName": {
              "name": "displayName",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": {
                "nonModel": "Image"
              },
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "legacyMenuSyncResp": {
          "name": "legacyMenuSyncResp",
          "fields": {
            "success": {
              "name": "success",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "error": {
              "name": "error",
              "isArray": false,
              "type": "AWSJSON",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Offer": {
          "name": "Offer",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "description": {
              "name": "description",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "expiryDate": {
              "name": "expiryDate",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "cardDescription": {
              "name": "cardDescription",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "termsAndConditions": {
              "name": "termsAndConditions",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "Reward": {
          "name": "Reward",
          "fields": {
            "id": {
              "name": "id",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "name": {
              "name": "name",
              "isArray": false,
              "type": "String",
              "isRequired": true,
              "attributes": []
            },
            "image": {
              "name": "image",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            },
            "isAvailable": {
              "name": "isAvailable",
              "isArray": false,
              "type": "Boolean",
              "isRequired": false,
              "attributes": []
            },
            "points": {
              "name": "points",
              "isArray": false,
              "type": "Int",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "LoyaltyIncentives": {
          "name": "LoyaltyIncentives",
          "fields": {
            "offers": {
              "name": "offers",
              "isArray": true,
              "type": {
                "nonModel": "Offer"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            },
            "rewards": {
              "name": "rewards",
              "isArray": true,
              "type": {
                "nonModel": "Reward"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": true
            }
          }
        },
        "MenuMetadata": {
          "name": "MenuMetadata",
          "fields": {
            "availabilityLastUpdatedAt": {
              "name": "availabilityLastUpdatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            },
            "menuLastUpdatedAt": {
              "name": "menuLastUpdatedAt",
              "isArray": false,
              "type": "AWSDateTime",
              "isRequired": true,
              "attributes": []
            }
          }
        },
        "ImageAsset": {
          "name": "ImageAsset",
          "fields": {
            "uri": {
              "name": "uri",
              "isArray": false,
              "type": "AWSURL",
              "isRequired": true,
              "attributes": []
            },
            "blurHash": {
              "name": "blurHash",
              "isArray": false,
              "type": "String",
              "isRequired": false,
              "attributes": []
            }
          }
        },
        "MenuItemCustomizationsDisplayGroups": {
          "name": "MenuItemCustomizationsDisplayGroups",
          "fields": {
            "order": {
              "name": "order",
              "isArray": true,
              "type": "String",
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            },
            "groups": {
              "name": "groups",
              "isArray": true,
              "type": {
                "nonModel": "MenuDisplayGroupsItems"
              },
              "isRequired": false,
              "attributes": [],
              "isArrayNullable": false
            }
          }
        }
      },
      "queries": {
        "getLoyaltyIncentives": {
          "name": "getLoyaltyIncentives",
          "isArray": false,
          "type": {
            "nonModel": "LoyaltyIncentives"
          },
          "isRequired": false,
          "arguments": {
            "storeNumber": {
              "name": "storeNumber",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "loyaltyId": {
              "name": "loyaltyId",
              "isArray": false,
              "type": "String",
              "isRequired": false
            }
          }
        }
      },
      "mutations": {
        "legacyMenuSync": {
          "name": "legacyMenuSync",
          "isArray": false,
          "type": {
            "nonModel": "legacyMenuSyncResp"
          },
          "isRequired": false,
          "arguments": {
            "restaurantId": {
              "name": "restaurantId",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "serviceMode": {
              "name": "serviceMode",
              "isArray": false,
              "type": "String",
              "isRequired": true
            },
            "region": {
              "name": "region",
              "isArray": false,
              "type": "String",
              "isRequired": true
            }
          }
        }
      }
    }
  },
  "version": "1.1"
}