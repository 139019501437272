/**
 * mParticle Provider RN Implementation
 * We will uncomment logic as we continue to implement various portions and swap in RN functionality.
 */

import * as Application from 'expo-application';
import { isUndefined, omitBy } from 'lodash';
import { ReactNode, createContext, useCallback, useContext, useMemo, useRef } from 'react';

import { ICartEntry, IServerOrder } from '@rbi-ctg/menu';
import { IUserOffersFeedbackEntry } from '@rbi-ctg/offers';
import { IGlobalAttributes } from '@rbilabs/mparticle-client';
import { useIsMobileBp } from 'hooks/breakpoints';
import { usePathname } from 'hooks/use-pathname';
import { getRoundUpDonations } from 'pages/cart/your-cart/totals/utils';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { useAmplitudeContext } from 'state/amplitude';
import {
  ExcludesNull,
  IAddToCartSelectionAttributes,
  IAmplitudeProduct,
  IAmplitudePurchaseEventAttributes,
  IAmplitudeUniversalAttributes,
  ILogPageView,
  ISignInEventOptions,
} from 'state/amplitude/types';
import { BranchEventNames } from 'state/branch/branch-event-names';
import { logBranchEvent } from 'state/branch/hooks/log-branch-event';
import {
  AllowedEvent,
  IChangeRestaurantAttributes,
  IChangeServiceModeAttributes,
  IDeliveryFeesSectionDisplayedAttributes,
  IDeliveryStoreAssignedAttributes,
  IScheduleFutureOrderAttributes,
} from 'state/crm-events/types';
import { useLocale } from 'state/intl';
import { ServiceMode } from 'state/order';
import { DeliveryFees } from 'state/service-mode/types';
import { StoreProxy } from 'state/store';
import { logBrazeCommerceEvent, logBrazeCustomEvent, setUserAttributes } from 'utils/braze';
import { brand, env } from 'utils/environment';
import { centsToDollars } from 'utils/index';
import logger from 'utils/logger';
import { convertQueryStringToObject } from 'utils/navigation';
import noop from 'utils/noop';
import { getInCodeLocalizedRouteForPath, routes } from 'utils/routing';

import { CustomEventNames, DialogViewComponentNames, EventTypes } from './constants';
import { ICRMEventsUniversalAttributes, ILogRBIEvent, IUtmParams, SignInPhases } from './types';
import {
  booleanToString,
  convertToSingleCommerceProduct,
  createCRMProducts,
  createProduct,
  expandProductAttributes,
  getCartDataItems,
  isCommerceEvent,
  isExpandableEvent,
  normalizeBooleans,
  reformatAttributesForSingularProduct,
  sanitizeValues,
  serializeNumberOfDriveThruWindows,
  serializePaymentType,
  serializePickupMode,
  serializeServiceMode,
  toAttributesWithValidLengthValues,
} from './utils';

export { ClickEventComponentNames, CustomEventNames, EventTypes } from './constants';

export interface ICRMEventsContext {
  logPurchase: (
    cartEntries: ICartEntry[],
    store: StoreProxy,
    serviceMode: ServiceMode,
    serverOrder: IServerOrder,
    attrs?: Record<string, any>
  ) => void;
  logAddOrRemoveFromCart: ({
    action,
    cartEntry,
    previousCartEntries,
    selectionAttrs,
  }: {
    action: 'add' | 'remove';
    cartEntry: ICartEntry;
    previousCartEntries: ICartEntry[];
    selectionAttrs?: IAddToCartSelectionAttributes | undefined;
  }) => void;
  logCheckoutEvent: (serviceMode: ServiceMode, cartEntries: ICartEntry[]) => void;
  logoutCRM: () => void;
  loginCRM: ({
    customerId,
    ...userAttributes
  }: {
    customerId?: string | undefined;
    [x: string]: any;
  }) => void;
  logRBIEvent: (event: AllowedEvent) => void;
  signUpEvent: ({ success }: { success: boolean }) => void;
  setUTMParamsFromUrl: (url: string) => void;
  logCommercePageView: (
    menuData: { id: string; name: string; menuType: string },
    attrs?: Record<string, string>
  ) => void;
  updateStaticRoutes: (newStaticRoutes: IStaticPageRoute[]) => void;
  updateUniversalAttributes: (_universalAttributes: Partial<IAmplitudeUniversalAttributes>) => void;
  signInEvent: (options: ISignInEventOptions) => void;
  logPageView: (pathname: string, store: StoreProxy, fees?: DeliveryFees) => void;
  logStoreLocatorTabView: (pathname: string, tabName: string) => void;
  logChangeServiceMode: (attributes: IChangeServiceModeAttributes) => void;
  logDeliveryFeesSectionDisplayed: (attributes: IDeliveryFeesSectionDisplayedAttributes) => void;
  logDeliveryStoreAssigned: (attributes: IDeliveryStoreAssignedAttributes) => void;
  logScheduleFutureOrder: (attributes: IScheduleFutureOrderAttributes) => void;
  logChangeRestaurant: (attributes: IChangeRestaurantAttributes) => void;
  logDialogView: (component: DialogViewComponentNames) => void;
}

const CRMEventsContext = createContext<ICRMEventsContext>({
  logPurchase: noop,
  logAddOrRemoveFromCart: noop,
  logCheckoutEvent: noop,
  loginCRM: noop,
  logoutCRM: noop,
  logRBIEvent: noop,
  logPageView: noop,
  logStoreLocatorTabView: noop,
  logChangeServiceMode: noop,
  logDeliveryFeesSectionDisplayed: noop,
  logDeliveryStoreAssigned: noop,
  signInEvent: noop,
  updateStaticRoutes: noop,
  logCommercePageView: noop,
  signUpEvent: noop,
  updateUniversalAttributes: noop,
  setUTMParamsFromUrl: noop,
  logScheduleFutureOrder: noop,
  logChangeRestaurant: noop,
  logDialogView: noop,
});

export const useCRMEventsContext = () => useContext<ICRMEventsContext>(CRMEventsContext);

export function CRMEventsProvider(props: { children: ReactNode }) {
  const { language, region } = useLocale();
  const isNewSignUpEvent = useRef(false);
  const pathname = usePathname();
  const isSmallScreen = useIsMobileBp();
  const {
    logAmplitudeCustomEvent,
    logAmplitudeRevenueEvent,
    setAmplitudeUserId,
    unsetAmplitudeUserId,
    updateAmplitudeUserAttributes,
  } = useAmplitudeContext();

  const staticRoutes = useRef<string[]>([]);
  const logPageViewParameters = useRef<ILogPageView>();

  /**
   * @returns current pathname from react native navigation, or the pathname if react native navigation is not enabled
   */
  const getSourcePage = useCallback(() => {
    return pathname;
  }, [pathname]);

  /**
   * store universal attributes for mParticle event/page views without re-rendering for changes in values
   */
  const universalAttributes = useRef<ICRMEventsUniversalAttributes>({
    'Service Mode': '',
    'Pickup Mode': '',
    isSmallScreen,
    currentBuild: Application.nativeApplicationVersion ?? '',
  });

  const deviceTime = useCallback(() => new Date().toTimeString().split(' ')[0], []);

  /**
   * Updates only the state of the universal Attributes
   */
  const updateUniversalAttributes = useCallback(
    (newAttributes: Partial<ICRMEventsUniversalAttributes>) => {
      universalAttributes.current = {
        ...universalAttributes.current,
        ...newAttributes,
      };
    },
    []
  );

  /**
   * Logs a Custom Event
   */
  const logRBIEvent: ILogRBIEvent = useCallback(
    (event: AllowedEvent) => {
      let universalAttrs = sanitizeValues(universalAttributes.current);
      universalAttrs = normalizeBooleans(universalAttrs);

      const globalAttributes: IGlobalAttributes = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
        currentScreen: getSourcePage(),
        deviceTime: deviceTime(),
        serviceMode: universalAttrs['Service Mode'],
        pickupMode: universalAttrs['Pickup Mode'],
        appBuild: universalAttrs.currentBuild,
      };

      const definedAttributes = {
        ...omitBy(globalAttributes, isUndefined),
        ...omitBy(event.globalAttributes, isUndefined),
        ...omitBy(event.attributes, isUndefined),
      };

      const attributes = toAttributesWithValidLengthValues(definedAttributes);

      if (isCommerceEvent(event.name)) {
        const products = attributes?.products ?? [];
        logBrazeCommerceEvent(event.name, products, attributes);
      } else {
        logBrazeCustomEvent(event.name, attributes);
      }

      // Expand the products if necessary for Amplitude
      if (isExpandableEvent(event.name)) {
        const products = attributes?.products ?? [];
        const expandedProducts = expandProductAttributes(products);
        logAmplitudeCustomEvent({
          name: event.name,
          attributes: { ...attributes, ...expandedProducts },
        });
      } else {
        logAmplitudeCustomEvent({ name: event.name, attributes });
      }
    },
    [deviceTime, getSourcePage, logAmplitudeCustomEvent, region]
  );

  const trackWhenStaticRoutesAvailable = ({ pathname, store }: ILogPageView) => {
    logPageViewParameters.current = {
      pathname,
      store,
    };
  };

  /**
   * Logs a Page view event
   */
  const logPageView = useCallback(
    (pathname: string, store: StoreProxy, fees?: DeliveryFees) => {
      // Checking if path is local path
      const isLocalRoute = Object.values(routes).some(route => {
        const localizedRoute = getInCodeLocalizedRouteForPath(route) || route;
        return pathname === '/' || (route !== '/' && pathname.startsWith(localizedRoute));
      });
      // If staticRoutes have not loaded yet
      // Store them for later when they are available
      if (!isLocalRoute && !staticRoutes.current.length) {
        return trackWhenStaticRoutesAvailable({ pathname, store });
      }
      logRBIEvent({
        name: CustomEventNames.PAGE_VIEW,
        type: EventTypes.Other,
        attributes: {
          path: pathname,
          restaurantId: store?.number ?? '',
          restaurantAddress: store?.physicalAddress?.address1 ?? '',
          restaurantZip: store?.physicalAddress?.postalCode ?? '',
          restaurantCity: store?.physicalAddress?.city ?? '',
          restaurantState: store?.physicalAddress?.stateProvince ?? '',
          restaurantCountry: store?.physicalAddress?.country ?? '',
          'Google.Page': '',
          'Google.DocumentReferrer': '',
          pathname,
          quotedFeeCents: fees?.deliveryTotalFee,
          referrer: '',
          sanityId: '',
        },
      });
    },
    [logRBIEvent]
  );

  /**
   * Logs a dialog view event
   */
  const logDialogView = useCallback(
    (component: DialogViewComponentNames) => {
      logRBIEvent({
        name: CustomEventNames.DIALOG_VIEW,
        type: EventTypes.Other,
        attributes: {
          component,
        },
      });
    },
    [logRBIEvent]
  );

  /**
   * Logs a Page view event
   */
  const logStoreLocatorTabView = useCallback(
    (pathname: string, tabName: string) => {
      logRBIEvent({
        name: CustomEventNames.STORE_LOCATOR_TAB_VIEW,
        type: EventTypes.Other,
        attributes: {
          path: pathname,
          tabName,
        },
      });
    },
    [logRBIEvent]
  );

  const logDeliveryFeesSectionDisplayed = useCallback(
    (attributes: IDeliveryFeesSectionDisplayedAttributes) => {
      logRBIEvent({
        name: CustomEventNames.DELIVERY_FEES_SECTION_DISPLAYED,
        type: EventTypes.Other,
        attributes,
      });
    },
    [logRBIEvent]
  );

  const logDeliveryStoreAssigned = useCallback(
    (attributes: IDeliveryStoreAssignedAttributes) => {
      logRBIEvent({
        name: CustomEventNames.DELIVERY_STORE_ASSIGNED,
        type: EventTypes.Other,
        attributes,
      });
    },
    [logRBIEvent]
  );

  const logChangeServiceMode = useCallback(
    (attributes: IChangeServiceModeAttributes) => {
      logRBIEvent({
        name: CustomEventNames.SELECT_SERVICE_MODE,
        type: EventTypes.Other,
        attributes,
      });
    },
    [logRBIEvent]
  );

  /**
   * Logs a Sign In Event
   */
  const signInEvent = useCallback(
    ({ phase, success }: ISignInEventOptions) => {
      const response = success ? 'Successful' : 'Failure';
      if (success && phase === SignInPhases.COMPLETE) {
        logBranchEvent(BranchEventNames.LOGIN);
      }

      if (phase === SignInPhases.START) {
        logRBIEvent({
          name: CustomEventNames.SIGN_IN_OTP_ATTEMPT,
          type: EventTypes.Other,
          attributes: {
            signInType: 'Email',
            response,
          },
        });
      }
    },
    [logRBIEvent]
  );

  const logCommercePageView = useCallback(
    (menuData: { id: string; name: string; menuType: string }, attrs = {}) => {
      try {
        const { name, id, menuType } = menuData;
        const product: Pick<
          IAmplitudeProduct,
          'id' | 'quantity' | 'name' | 'price' | 'total_product_amount'
        > = {
          id,
          name,
          quantity: 1,
          price: 0,
          total_product_amount: 0,
        };
        const singleProduct = convertToSingleCommerceProduct(product);
        const viewDetailAttributes = { menuType, ...attrs };
        const viewDetailItemAttributes = { menuType, products: [], ...singleProduct, ...attrs };

        logRBIEvent({
          name: CustomEventNames.E_COMMERCE_VIEW_DETAIL,
          type: EventTypes.Other,
          attributes: { ...viewDetailAttributes, products: [product] },
        });

        logRBIEvent({
          name: CustomEventNames.E_COMMERCE_VIEW_DETAIL_ITEM,
          type: EventTypes.Other,
          attributes: {
            ...viewDetailItemAttributes,
          },
        });
      } catch (error) {
        logger.error('Error logging CRM eCommerce - View Detail event');
      }
    },
    [logRBIEvent]
  );

  const signUpEvent = useCallback(
    ({ success }: { success: boolean }) => {
      if (success) {
        logBranchEvent(BranchEventNames.COMPLETE_REGISTRATION);
        isNewSignUpEvent.current = true;
        logRBIEvent({
          name: CustomEventNames.SIGN_UP_SUCCESSFUL,
          type: EventTypes.Other,
          attributes: {
            signUpType: 'Email',
          },
        });
      }
    },
    [logRBIEvent]
  );

  const logCheckoutEvent = useCallback(
    (serviceMode: ServiceMode, cartEntries: ICartEntry[]) => {
      try {
        const products = cartEntries.map(createProduct).filter(Boolean as any as ExcludesNull);
        const pickUpMode = serializePickupMode(serviceMode);
        const customAttributes = {
          'Pickup Mode': pickUpMode,
          'Cart Data': getCartDataItems(cartEntries),
          'Total Amount': products.reduce((acc, curr) => acc + curr.total_product_amount, 0),
          'Product Count': products.length,
        };

        logRBIEvent({
          name: CustomEventNames.E_COMMERCE_CHECKOUT,
          type: EventTypes.Other,
          attributes: { ...customAttributes, products },
        });

        for (const product of products) {
          const singleProduct = convertToSingleCommerceProduct(product);
          logRBIEvent({
            name: CustomEventNames.E_COMMERCE_CHECKOUT_ITEM,
            type: EventTypes.Other,
            attributes: { ...customAttributes, ...singleProduct, products: [] },
          });
        }
      } catch (error) {
        logger.error('Error logging CRM eCommerce - Checkout event');
      }
    },
    [logRBIEvent]
  );

  const loginCRM = useCallback(
    ({ customerId = '', ...userAttributes }) => {
      const normalizedAttrs = normalizeBooleans(userAttributes);
      updateUniversalAttributes(normalizedAttrs);
      if (customerId) {
        setAmplitudeUserId({ customerId });
      }

      // fire log sign up flow successful after successful login
      if (isNewSignUpEvent.current) {
        logRBIEvent({
          name: CustomEventNames.SIGN_UP_FLOW_SUCCESSFUL,
          type: EventTypes.Other,
          attributes: {
            signUpType: 'Email',
          },
        });
      }
    },
    [logRBIEvent, setAmplitudeUserId, updateUniversalAttributes]
  );

  const logoutCRM = useCallback(() => {
    unsetAmplitudeUserId();
  }, [unsetAmplitudeUserId]);

  const logAddOrRemoveFromCart = useCallback(
    ({
      action,
      cartEntry,
      previousCartEntries,
      selectionAttrs,
    }: {
      action: 'add' | 'remove';
      cartEntry: ICartEntry;
      previousCartEntries: ICartEntry[];
      selectionAttrs?: IAddToCartSelectionAttributes;
    }) => {
      const product = createProduct(cartEntry);
      if (!product) {
        return;
      }
      if (action === 'add') {
        logBranchEvent(BranchEventNames.ADD_TO_CART);
      }

      const cartId = 'lineId' in cartEntry ? cartEntry.lineId : cartEntry.cartId;
      const attributes = {
        isDonation: cartEntry.isDonation ?? false,
        isExtra: cartEntry.isExtra ?? false,
        cartId: cartId || cartEntry._id,
        'Is Kiosk': booleanToString(false),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Is Update': booleanToString(false),
        'Cart Data': getCartDataItems(previousCartEntries),
        'Picker Aspect Selection': booleanToString(!!selectionAttrs?.pickerAspectSelection),
        'Combo Slot Selection': booleanToString(!!selectionAttrs?.comboSlotSelection),
        'Item Modified': booleanToString(!!selectionAttrs?.itemModified),
        'Total Amount': product.total_product_amount,
        'Pickup Mode': universalAttributes.current?.['Pickup Mode'] ?? '',
      };

      logRBIEvent({
        name:
          action === 'add'
            ? CustomEventNames.E_COMMERCE_ADD_TO_CART
            : CustomEventNames.E_COMMERCE_REMOVE_FROM_CART,
        type: EventTypes.Other,
        attributes: { ...attributes, products: [product] },
      });

      const singleProduct = convertToSingleCommerceProduct(product);

      logRBIEvent({
        name:
          action === 'add'
            ? CustomEventNames.E_COMMERCE_ADD_TO_CART_ITEM
            : CustomEventNames.E_COMMERCE_REMOVE_FROM_CART_ITEM,
        type: EventTypes.Other,
        attributes: { products: [], ...attributes, ...singleProduct },
      });
    },
    [deviceTime, getSourcePage, logRBIEvent]
  );

  const updateStaticRoutes = useCallback(
    (newStaticRoutes: IStaticPageRoute[]) => {
      staticRoutes.current = newStaticRoutes.reduce((acc: string[], route) => {
        const staticPath = route?.localePath?.[language]?.current || route?.path?.current;
        if (staticPath) {
          acc.push(`/${staticPath}`);
        }
        return acc;
      }, []);

      if (staticRoutes.current.length && logPageViewParameters.current) {
        const { store, pathname } = logPageViewParameters.current;
        logRBIEvent({
          name: CustomEventNames.PAGE_VIEW,
          type: EventTypes.Other,
          attributes: {
            path: pathname,
            restaurantId: store?.number ?? '',
            restaurantAddress: store?.physicalAddress?.address1 ?? '',
            restaurantZip: store?.physicalAddress?.postalCode ?? '',
            restaurantCity: store?.physicalAddress?.city ?? '',
            restaurantState: store?.physicalAddress?.stateProvince ?? '',
            restaurantCountry: store?.physicalAddress?.country ?? '',
            'Google.Page': '',
            'Google.DocumentReferrer': '',
            pathname,
            sanityId: '',
            referrer: '',
          },
        });

        logPageViewParameters.current = undefined;
      }
    },
    [language, logRBIEvent]
  );

  const logPurchase = useCallback(
    (
      cartEntries: ICartEntry[],
      store: StoreProxy,
      serviceMode: ServiceMode,
      serverOrder: IServerOrder,
      attrs: Record<string, any> = {}
    ) => {
      const couponIDs = (serverOrder.cart.offersFeedback || []).map(
        (feedbackEntry: IUserOffersFeedbackEntry) => {
          return feedbackEntry.couponId;
        }
      );

      const upsells = cartEntries.filter(entry => entry.isUpsell);
      const hasUpsell = !!upsells.length;
      const upsellTotal = centsToDollars(
        upsells.reduce((total, entry) => total + (entry.price || 0), 0)
      );

      const couponIDString = couponIDs.join();
      const serializedServiceMode = serializeServiceMode(serviceMode);

      const rewardAttributes = serverOrder.cart.rewardsApplied?.map(reward => ({
        'Reward ID': reward.rewardId,
        'Reward Quantity': reward.timesApplied,
      }));

      const transactionAttributes = {
        revenue: centsToDollars(serverOrder.cart.subTotalCents),
        tax: centsToDollars(serverOrder.cart.taxCents),
      };

      const roundUpDonation = getRoundUpDonations(serverOrder);
      const products = createCRMProducts({ serverOrder, cartEntries });

      // Some of these are duplicates from transactionAttributes,
      // but BI wants to have them under specific property names.
      const additionalAttrs: IAmplitudePurchaseEventAttributes = {
        brand: brand().toUpperCase(),
        region,
        env: env() as string,
        'Pickup Mode': serializePickupMode(serviceMode),
        'Service Mode': serializedServiceMode,
        branch_service_mode: serializedServiceMode,
        customer_event_alias: serializedServiceMode,
        'CC Token': serverOrder?.cart?.payment?.panToken ?? null,
        'Coupon ID': couponIDString,
        'Coupon Applied': booleanToString(couponIDs.length > 0),
        Currency: attrs.currencyCode,
        'Tax Amount': transactionAttributes.tax,
        'Total Amount': transactionAttributes.revenue,
        'Transaction Order Number ID': serverOrder?.posOrderId ?? '',
        'Transaction POS': serverOrder?.cart?.posVendor ?? null,
        'Transaction RBI Cloud Order ID': serverOrder?.rbiOrderId ?? null,
        'Timed Fire Minutes': attrs.fireOrderInMinutes,
        'Restaurant ID': store.number,
        'Restaurant Name': store.name,
        'Restaurant Number': store.number,
        'Restaurant Address': store.physicalAddress?.address1 ?? null,
        'Restaurant City': store.physicalAddress?.city ?? null,
        'Restaurant State/Province Name': store.physicalAddress?.stateProvince ?? null,
        'Restaurant Postal Code': store.physicalAddress?.postalCode ?? null,
        'Restaurant Country': store.physicalAddress?.country ?? null,
        'Restaurant Latitude': store.latitude,
        'Restaurant Longitude': store.longitude,
        'Restaurant Status': store.status,
        'Restaurant Drink Station Type': store.drinkStationType,
        'Restaurant Drive Thru Lane Type': store.driveThruLaneType ?? null,
        'Restaurant Franchise Group Id': store.franchiseGroupId,
        'Restaurant Franchise Group Name': store.franchiseGroupName,
        'Restaurant Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Breakfast': store.hasBreakfast,
        'Restaurant Has Burgers For Breakfast': store.hasBurgersForBreakfast,
        'Restaurant Has Curbside': store.hasCurbside,
        'Restaurant Has Front Counter Closed': store.frontCounterClosed,
        'Restaurant Has Catering': store.hasCatering,
        'Restaurant Has Dine In': store.hasDineIn,
        'Restaurant Has Drive Thru': store.hasDriveThru,
        'Restaurant Has Home Delivery': store.hasDelivery,
        'Restaurant Has Mobile Ordering': store.hasMobileOrdering,
        'Restaurant Has Parking': store.hasParking,
        'Restaurant Has Playground': store.hasPlayground,
        'Restaurant Has Take Out': store.hasTakeOut,
        'Restaurant Has Wifi': store.hasWifi,
        'Restaurant Number Drive Thru Windows': serializeNumberOfDriveThruWindows(
          store.driveThruLaneType
        ),
        'Restaurant Parking Type': store.parkingType,
        'Restaurant Playground Type': store.playgroundType,
        'Restaurant POS': store.pos?.vendor ?? null,
        'Restaurant POS Version': store.pos?.version ?? null,
        'Is Kiosk': false,
        'Card Type': serverOrder.cart.payment?.cardType || '',
        'Payment Type': serializePaymentType(serverOrder.cart.payment?.paymentType),
        'Has Upsell': hasUpsell,
        'Upsell Total': String(upsellTotal),
        'Device Time': deviceTime(),
        'Source Page': getSourcePage(),
        'Cart Data': getCartDataItems(cartEntries),
        Rewards: rewardAttributes ? JSON.stringify(rewardAttributes) : null,
        'Is Loyalty': !!serverOrder.loyaltyTransaction,
        roundUpAmount: roundUpDonation?.totalCents ?? 0,
        'Product Count': products.length,
      };

      // Delivery Fees
      if (serializeServiceMode(serviceMode) === 'Delivery') {
        additionalAttrs.deliveryFeeAmount = centsToDollars(attrs.deliveryFeeCents);
        additionalAttrs.deliveryDiscountAmount = centsToDollars(attrs.deliveryFeeDiscountCents);
        additionalAttrs.deliveryGeographicalFeeAmount = centsToDollars(
          attrs.deliveryGeographicalFeeCents
        );
        additionalAttrs.deliveryServiceFeeAmount = centsToDollars(attrs.deliveryServiceFeeCents);
        additionalAttrs.deliverySmallCartFeeAmount = centsToDollars(
          attrs.deliverySmallCartFeeCents
        );
        additionalAttrs.totalDeliveryOrderFeeAmount = centsToDollars(
          attrs.totalDeliveryOrderFeesCents
        );
        additionalAttrs.deliverySurchargeFeeAmount = centsToDollars(
          attrs.deliverySurchargeFeeCents
        );
        additionalAttrs.quotedFeeAmount = centsToDollars(attrs.quotedFeeCents);
        additionalAttrs.baseDeliveryFeeAmount = centsToDollars(attrs.baseDeliveryFeeCents);
      }

      if (transactionAttributes.revenue >= 20) {
        additionalAttrs['Value Threshold 20 Met'] = true;
      }

      if (transactionAttributes.revenue >= 15) {
        additionalAttrs['Value Threshold 15 Met'] = true;
      }

      if (transactionAttributes.revenue >= 10) {
        additionalAttrs['Value Threshold 10 Met'] = true;
      }
      if (transactionAttributes.revenue >= 5) {
        additionalAttrs['Value Threshold 5 Met'] = true;
      }

      const normalizedTransactionAttrs = normalizeBooleans(transactionAttributes);
      const sanitizedAttrs = sanitizeValues(additionalAttrs);
      const normalizedAttrs = normalizeBooleans(sanitizedAttrs);

      try {
        logBranchEvent(BranchEventNames.PURCHASE, {
          revenue: transactionAttributes.revenue,
          tax: transactionAttributes.tax,
          transactionID: serverOrder.rbiOrderId,
          currency: attrs?.currencyCode || 'USD',
        });

        logRBIEvent({
          name: 'eCommerce - Purchase',
          type: EventTypes.Other,
          attributes: {
            ...normalizedAttrs,
            ...normalizedTransactionAttrs,
            products,
          },
        });

        for (const product of products) {
          const reformattedProduct = reformatAttributesForSingularProduct(product);
          logRBIEvent({
            name: CustomEventNames.E_COMMERCE_PURCHASE_ITEM,
            type: EventTypes.Other,
            attributes: {
              products: [],
              ...normalizedAttrs,
              ...normalizedTransactionAttrs,
              ...reformattedProduct,
            },
          });
        }

        logAmplitudeRevenueEvent({
          totalAmount: transactionAttributes.revenue,
          eventProperties: { ...normalizedAttrs },
        });
      } catch (error) {
        logger.error({ error, message: 'Error logging eCommerce - Purchase event' });
      }
      // log rbi purchase events
      if (serializedServiceMode === 'Pickup' || serializedServiceMode === 'Delivery') {
        const eventName =
          serializedServiceMode === 'Pickup'
            ? CustomEventNames.PURCHASE_PICK_UP
            : CustomEventNames.PURCHASE_DELIVERY;
        logRBIEvent({
          name: eventName,
          type: EventTypes.Other,
        });

        logBranchEvent(eventName);
      }
    },
    [deviceTime, getSourcePage, logAmplitudeRevenueEvent, logRBIEvent, region]
  );

  /**
   * Updates UTM Attributes in the universal attributes.
   * Used For attribution tracking.
   */
  const setUTMParamsFromUrl = useCallback(
    (url: string) => {
      const {
        utm_source = '',
        utm_campaign = '',
        utm_medium = '',
        utm_content = '',
        utm_term = '',
      } = convertQueryStringToObject<IUtmParams>(url);

      if (utm_source || utm_campaign || utm_medium || utm_content || utm_term) {
        const attributes = {
          'UTM Source': utm_source,
          'UTM Medium': utm_medium,
          'UTM Campaign': utm_campaign,
          'UTM Term': utm_term,
          'UTM Content': utm_content,
        };
        updateUniversalAttributes(attributes);
        updateAmplitudeUserAttributes(attributes);
        setUserAttributes(attributes);
      }
    },
    [updateUniversalAttributes, updateAmplitudeUserAttributes]
  );

  /**
   * Logs an event when costumer schedules delivery for closed restaurant
   */
  const logScheduleFutureOrder = useCallback(
    (attributes: IScheduleFutureOrderAttributes) => {
      logRBIEvent({
        name: CustomEventNames.SCHEDULE_FUTURE_ORDER,
        type: EventTypes.Other,
        attributes,
      });
    },
    [logRBIEvent]
  );

  /**
   * Logs an event when costumer chooses to change restaurant
   */
  const logChangeRestaurant = useCallback(
    (attributes: IChangeRestaurantAttributes) => {
      logRBIEvent({
        name: CustomEventNames.CHANGE_RESTAURANT,
        type: EventTypes.Other,
        attributes,
      });
    },
    [logRBIEvent]
  );

  const crmEventsContext = useMemo<ICRMEventsContext>(
    () => ({
      logPurchase,
      logAddOrRemoveFromCart,
      logCheckoutEvent,
      logCommercePageView,
      loginCRM,
      logoutCRM,
      logPageView,
      logRBIEvent,
      logStoreLocatorTabView,
      logChangeServiceMode,
      logDeliveryFeesSectionDisplayed,
      logDeliveryStoreAssigned,
      setUTMParamsFromUrl,
      signInEvent,
      signUpEvent,
      updateStaticRoutes,
      updateUniversalAttributes,
      logScheduleFutureOrder,
      logChangeRestaurant,
      logDialogView,
    }),
    [
      logPurchase,
      logAddOrRemoveFromCart,
      logCheckoutEvent,
      logCommercePageView,
      loginCRM,
      logoutCRM,
      logPageView,
      logRBIEvent,
      logStoreLocatorTabView,
      logChangeServiceMode,
      logDeliveryFeesSectionDisplayed,
      logDeliveryStoreAssigned,
      setUTMParamsFromUrl,
      signInEvent,
      signUpEvent,
      updateStaticRoutes,
      updateUniversalAttributes,
      logScheduleFutureOrder,
      logChangeRestaurant,
      logDialogView,
    ]
  );

  return (
    <CRMEventsContext.Provider value={crmEventsContext}>{props.children}</CRMEventsContext.Provider>
  );
}

export default CRMEventsContext.Consumer;
