import { router } from 'expo-router';
import { Redirect } from 'expo-router';
import { useCallback, useMemo } from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  RefreshControl,
  View,
  useWindowDimensions,
} from 'react-native';

import {
  ErrorCard,
  IconOffer,
  IconRefresh,
  ScrollView,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';
import { LoyaltyPromoCode } from '@fhs-legacy/frontend/src/pages/loyalty/loyalty-offers/loyalty-promo-code';

import { OfferCard, OfferCardProps } from '../../components/offer-card';
import { OfferCardSkeleton } from '../../components/offer-card-skeleton';
import { SwitchToDelivery } from '../../components/switch-to-delivery';
import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';
import { useLoyaltyIncentives } from '../../queries/loyalty.queries';

export function AllOffersPage() {
  const mqStyles = useMqStyles();
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);

  const { width } = useWindowDimensions();
  // edge case for screens like the iphone SE 320x568
  const isSmallPhone = width <= 320;
  const { loyaltyUserReady } = useLoyaltyLegacyStates();

  const {
    data,
    isError,
    isLoading: isLoyaltyIncentivesLoading,
    isFetching,
    refetch,
  } = useLoyaltyIncentives();

  const isLoading = isLoyaltyIncentivesLoading || !loyaltyUserReady || isFetching;
  const offerCardProps = useMemo(() => {
    const offerCards =
      data?.offers?.reduce<OfferCardProps[]>((offerList, offer) => {
        if (offer && offer.name && offer.image && offer.cardDescription) {
          offerList.push({
            imageUrl: offer.image,
            title: offer.name,
            description: offer.cardDescription,
            onPressButton: () => null,
            onPressImage: () => {
              router.navigate(`/v2/offers/${offer.id}`);
            },
            buttonLabel: offer.isAvailable ? 'Apply Offer' : 'See Details',
          });
        }

        return offerList;
      }, []) ?? [];

    if (!isSmallPhone && offerCards.length % 2 === 1) {
      offerCards.push({
        isHidden: true,
        imageUrl: '',
        title: '',
        description: '',
        buttonLabel: '',
        onPressButton: () => {},
        onPressImage: () => {},
      });
    }
    return offerCards;
  }, [data?.offers, isLoading]);

  const renderOffer = useCallback(
    ({ item }: ListRenderItemInfo<OfferCardProps>) => {
      const smallPhoneStyle = isSmallPhone ? [mqStyles.smallPhoneScreen] : [];

      return <OfferCard {...item} style={[mqStyles.item, ...smallPhoneStyle]} />;
    },
    [isSmallPhone, mqStyles.smallPhoneScreen, mqStyles.item]
  );

  const renderLoadingOffer = () => {
    const smallPhoneStyle = isSmallPhone ? [mqStyles.smallPhoneSkeletonItem] : [];

    return <OfferCardSkeleton style={smallPhoneStyle} textBlockLines={isSmallPhone ? 2 : 3} />;
  };

  const customProps = isSmallPhone
    ? {}
    : {
        numColumns: 2,
        columnWrapperStyle: mqStyles.column,
      };

  const smallPhoneKey = isSmallPhone ? 'small-screen' : 'normal-screen';
  const content = isError ? (
    <ErrorCard
      description={'We ran into an issue loading the content of this page. Please try again.'}
      buttonType="outline"
      buttonIcon={<IconRefresh color={tokens.colors.$houseRedDarken} />}
      buttonTitle={'Reload'}
      icon={<IconOffer size={68} color={tokens.colors.$blackOpacity10} />}
      buttonHandle={refetch}
    />
  ) : (
    <FlatList
      key={smallPhoneKey}
      style={mqStyles.content}
      contentContainerStyle={[mqStyles.flatList]}
      data={isLoading ? new Array(4).fill({}) : offerCardProps}
      renderItem={isLoading ? renderLoadingOffer : renderOffer}
      {...customProps}
    />
  );

  if (isDesktop) {
    return <Redirect href="/v2/offers" />;
  }

  return (
    <ScrollView
      style={mqStyles.page}
      refreshControl={<RefreshControl refreshing={isFetching} onRefresh={refetch} />}
    >
      <View>
        <View style={mqStyles.header}>
          <LoyaltyPromoCode buttonLabel={'Have a Promo Code?'} style={mqStyles.promoButton} />
        </View>
        {content}
        <View style={mqStyles.footer}>
          <SwitchToDelivery style={mqStyles.switchToDelivery} />
        </View>
      </View>
    </ScrollView>
  );
}

const useMqStyles = createMqStyles({
  page: {
    $base: {
      backgroundColor: tokens.colors.$white,
      paddingHorizontal: 16,
      flexDirection: 'column',
      paddingBottom: 16,
    },
  },
  content: {
    $base: {
      flexGrow: 0,
    },
    $gteDesktop: {
      alignSelf: 'center',
    },
  },
  header: {
    $base: {
      alignItems: 'center',
    },
  },
  footer: {
    $gteDesktop: {
      alignSelf: 'center',
    },
  },
  switchToDelivery: {
    $base: {
      marginTop: 12,
    },
  },
  flatList: {
    $base: {
      justifyContent: 'center',
      rowGap: 12,
      paddingBottom: 4,
      flexGrow: 0,
    },
  },
  item: {
    $base: {
      minWidth: 160,
      maxWidth: 400,
      flex: 0.5,
    },
  },
  column: {
    $base: {
      gap: 12,
    },
  },
  promoButton: {
    $base: {
      marginTop: 12,
    },
    $ltDesktop: {
      width: '100%',
    },
  },
  smallPhoneScreen: {
    $base: {
      flex: 1,
      width: '100%',
    },
  },
  smallPhoneSkeletonItem: {
    $base: {
      width: '100%',
    },
  },
});
